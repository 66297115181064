import {Component, Input} from '@angular/core';
import {IWeather} from '../../shared/model/weather';
import {ICalculatedMeasure} from '../../shared/model/calculatedMeasure';

@Component({
  selector: 'app-pane-results',
  styleUrls: ['./paneResults.component.scss'],
  templateUrl: './paneResults.component.html'
})
export class PaneResultsComponent {
  @Input() weather: IWeather;
  @Input() measures: ICalculatedMeasure[];

  metricUp(item) {
    if (!this.measures[0] || !this.measures[1]) {
      return false;
    }

    return this.measures[0][item] > this.measures[1][item];
  }

  metricDown(item) {
    if (!this.measures[0] || !this.measures[1]) {
      return false;
    }

    return this.measures[0][item] < this.measures[1][item];
  }
}
