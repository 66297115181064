import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ICustomer} from '../shared/model/customer';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  customer: ICustomer;
  constructor(private route: ActivatedRoute, private renderer: Renderer2) {
    this.customer = route.snapshot.parent.data.customer;
  }

  ngOnInit() {
    this.renderer.addClass(document.querySelector('html'), 'page-notfound');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('html'), 'page-notfound');
  }
}
