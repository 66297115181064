export class BeehiveInfo {
  public temperatureInfo = `Pour être une donnée pertinente,
  la température doit être à la fois mesurée à l’intérieur et à l’extérieur de la ruche.
   En effet, la température extérieure de la ruche conditionne la vie des abeilles.
   Celles-ci veillent à maintenir une température acceptable pour le développement de la colonie.`;
  public externalTemperature = 'La température à l’extérieur de la ruche à l’instant T.';
  public temperature = 'La température à l’intérieur de la ruche à l’instant T.';
  public temperatureEvolution = 'L’évolution de la température depuis la dernière heure.';

  public humidityInfo = `Une hygrométrie (taux d’humidité) satisfaisante est essentielle pour permettre un développement
   optimal du couvain. Comme pour la température, on mesure à la fois l’humidité extérieure et intérieure de la ruche
    car l’hygrométrie extérieure issue des conditions climatiques a une incidence sur la vie de la colonie.`;
  public externalHumidity = 'Le taux d’humidité à l’intérieur de la ruche à l’instant T.';
  public humidity = 'Le taux d’humidité à l’extérieur de la ruche  à l’instant T.';
  public humidityEvolution = 'L’évolution de l\'humidité depuis la dernière heure.';

  public weightInfo = `
  Le poids de votre ruche comprend&nbsp;: <br>
      <ul>
        <li>Le poids de la structure, du toit et des cadres vides de la ruche</li>
        <li>Le poids des abeilles et du couvain</li>
        <li>Le poids des parties organiques de la ruche : cire d’abeille, miel, propolis, gelée royale, etc.</li>
      </ul>
  Il peut varier de manière significative d’un jour à l’autre.
  Il s’agit d’un paramètre important car il permet de mesurer rapidement la taille de la colonie.
  `;
  public weight = 'Le poids total de votre ruche à l’instant T.';
  public weightEvolution = 'L’évolution du poids total depuis la dernière heure.';
}
