import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {ICustomer} from '../model/customer';
import {Observable} from 'rxjs';

@Injectable()
export class BeehiveRedirectResolve implements Resolve<ICustomer> {
  constructor(private router: Router) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICustomer> | Promise<ICustomer> | ICustomer {
    const customer = route.parent.data.customer;
    if (customer.beehives.length === 1) {
      this.router.navigate(['/clients/', customer.id, 'ruches', customer.beehives[0].id]);
    }
    return customer;
  }
}
