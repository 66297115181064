import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BeehivesComponent} from './beehives/beehives.component';
import {OverviewComponent} from './overview/overview.component';
import {CustomerResolve} from './shared/resolve/customer.resolve';
import {NotFoundComponent} from './notfound/notfound.component';
import {AbstractComponent} from './abstract/abstract.component';
import {ArticlesComponent} from './articles/articles.component';
import {ArticlesResolve} from './shared/resolve/articles.resolve';
import {CategoriesResolve} from './shared/resolve/categories.resolve';
import {CategoryResolve} from './shared/resolve/category.resolve';
import {ArticleComponent} from './articles/article/article.component';
import {ArticleResolve} from './shared/resolve/article.resolve';
import {BeehiveComponent} from './beehives/beehive/beehive.component';
import {BeehiveResolve} from './shared/resolve/beehive.resolve';
import {Period} from './utils/enum/period.enum';
import {BeehiveRedirectResolve} from './shared/resolve/beehiveRedirect.resolve';

const routes: Routes = [
  {
    path: 'clients/:customerId',
    component: AbstractComponent,
    resolve: {
      customer: CustomerResolve
    },
    children: [
      {
        path: 'ruches',
        component: BeehivesComponent,
        resolve: {
          // customerRedirect: BeehiveRedirectResolve
        },
      },
      {
        path: 'ruches/:beehiveId',
        component: BeehiveComponent,
        resolve: {
          beehive: BeehiveResolve
        },
        data: {
          period: Period.Daily
        }
      },
      {
        path: 'actualites',
        resolve: {
          categories: CategoriesResolve
        },
        children: [
          {
            path: '',
            component: ArticlesComponent,
            resolve: {
              articles: ArticlesResolve,
              category: CategoryResolve
            },
            data: {
              categoryId: 'all'
            }
          }
        ]
      },
      {
        path: 'actualites/:articleId',
        component: ArticleComponent,
        resolve: {
          article: ArticleResolve
        }
      },
      {
        path: 'overview',
        component: OverviewComponent
        // resolve: {
        //   article: ArticleResolve
        // }
        // loadChildren: () => import('../app/overview/overview.component').then(m => m.OverviewComponent)
      },
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full'
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' } )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
