import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import { ApexCharts } from 'apexcharts/dist/apexcharts.common.js';
import * as fr from 'apexcharts/src/locales/fr.json';
import {ChartItem} from '../../shared/dataModel/chartItem';
import * as Bowser from 'bowser';
import {ApexChart, ApexXAxis, ApexYAxis, ApexStroke, ApexTooltip, ApexFill, ApexDataLabels} from 'ng-apexcharts';

@Component({
  selector: 'app-line-chart',
  templateUrl: './lineChart.component.html'
})
export class LineChartComponent implements OnChanges {

  @ViewChild('chart') chartElm: ElementRef;
  @Input() dataset: ChartItem[];
  @Input() yaxis: string;
  @Input() options: any;
  chart: ApexCharts;
  fr: any = fr;
  chartConfig: ApexChart;
  xaxisConfig: any;
  yaxisConfig: ApexYAxis;
  colors: string[];
  strokeConfig: ApexStroke;
  tooltipConfig: ApexTooltip;
  fillConfig: ApexFill;
  dataLabelsConfig: ApexDataLabels;

  constructor() {
    this.chartConfig = {
      type: 'line',
      fontFamily: 'Roboto',
      locales: [this.fr.default],
      defaultLocale: 'fr',
      height: 350,
      stacked: false,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    };

    this.xaxisConfig = {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
      tooltip: {
        enabled: false
      }
    };

    this.yaxisConfig = {
      min: 0,
      labels: {
        formatter: (value) => {
          return `${Math.ceil(value)}${this.yaxis}`;
        }
      }
    };

    this.dataLabelsConfig = {
      enabled: false
    };

    this.tooltipConfig = {
      shared: true,
      followCursor: false,
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      items: {
        display: 'flex'
      },
      fixed: {
        enabled: false,
        position: 'absolute',
        offsetX: 0,
        offsetY: 0
      }
    };

    this.strokeConfig = {
      width: 3,
      curve: 'smooth'
    };

    const browser = Bowser.getParser(window.navigator.userAgent);
    const isInvalidBrowser = browser.satisfies({
      safari: '> 7'
    });

    this.fillConfig = {};

    if (!isInvalidBrowser) {
      this.chartConfig.type = 'area';

      this.fillConfig = {
        type: 'gradient',
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: .65,
          opacityTo: 0,
          stops: [0, 100, 100, 100]
        }
      };
    }
  }

  ngOnChanges() {
    if (this.dataset.length && this.dataset[0].data.length) {
      const series = this.dataset;
      const colors = [];

      for (const serie of series) {
        colors.push(serie.color);
      }

      this.yaxisConfig.max = Math.max(...series.map(serie => Math.max(...serie.data.map(item => item.y)))) + 5;

      this.colors = colors;
    }
  }
}
