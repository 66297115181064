
import {throwError as observableThrowError, Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Router} from '@angular/router';

export class AbstractResolve {
  constructor(protected router: Router) {}

  redirectToNotFoundPage(err: any, route: ActivatedRouteSnapshot) {
    const customerId = route.parent.paramMap.get('customerId');
    if (customerId) {
      this.router.navigate(['/clients', customerId, '404']);
    } else {
      this.router.navigate(['/404']);
    }

    return observableThrowError(err);
  }
}
