import {Injectable} from '@angular/core';
import {AbstractDao} from './abstract.dao';
import {Restangular} from 'ngx-restangular';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {IMeasure} from '../model/measure';
import {PeriodHelper} from '../../utils/helpers/period.helper';
import {IBeehive} from '../model/beehive';

@Injectable()
export class BeehiveDao extends AbstractDao {
  path = '/beehives';

  constructor(protected restangular: Restangular, private httpClient: HttpClient, private periodHelper: PeriodHelper) {
    super(restangular);
  }

  getBeehive(id) {
    return this.httpClient.get<IBeehive>(this.getOnePath(id));
  }

  getMeasures(id: number, period: string): Observable<IMeasure[]> {
    const queryParams = this.periodHelper.periodToQueryParams('recorded', period);

    return this.httpClient
      .get<IMeasure[]>(`${this.getOnePath(String(id))}/measures`, {
      params: queryParams
    });
  }
}
