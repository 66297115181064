import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {AbstractDao} from './abstract.dao';
import {Observable} from 'rxjs';
import {IWeather} from '../model/weather';

@Injectable()
export class WeatherDao extends AbstractDao {
  path = '/weathers';

  constructor(protected restangular: Restangular) {
    super(restangular);
  }

  getCurrentWeather(city: string): Observable<IWeather> {
    return this.getPath(city).get();
  }
}
