import {Injectable} from '@angular/core';
import {IArticle} from '../model/article';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {CustomerDao} from '../dao/customer.dao';
import {AbstractResolve} from './abstract.resolve';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ArticlesResolve extends AbstractResolve implements Resolve<IArticle[]> {
  constructor(private customerDao: CustomerDao, router: Router) {
    super(router);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IArticle[]> | Promise<IArticle[]> | IArticle[] {
    const customerId = route.parent.parent.paramMap.get('customerId');
    const categoryId = route.queryParamMap.get('categoryId');
    const page = Number(route.queryParamMap.get('page')) || 1;

    return this.customerDao
      .getArticles(customerId, page, categoryId)
        .pipe(catchError(val =>  this.redirectToNotFoundPage(val, route)));
  }
}
