import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {IArticle} from '../shared/model/article';
import {ICustomer} from '../shared/model/customer';
import {ICategory} from '../shared/model/category';
import {CustomerDao} from '../shared/dao/customer.dao';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  articles: IArticle[];
  customer: ICustomer;
  categories: ICategory[];
  categoryId = 'all';
  page = 1;
  currentPage = 1;

  constructor(private router: Router, private route: ActivatedRoute, private customerDao: CustomerDao,
              private _scrollToService: ScrollToService) {
  }

  private init() {
    this.customer = this.route.snapshot.parent.parent.data.customer;
    this.articles = this.route.snapshot.data.articles;
    this.categories = this.route.parent.snapshot.data.categories;
    this.categoryId = this.route.snapshot.queryParams['categoryId'] || this.categoryId;
    this.page = this.route.snapshot.queryParams['page'] || this.page;
  }

  ngOnInit(): void {
    this.init();
    // this.route.queryParamMap.subscribe((map: ParamMap) => {
    //   const categoryId = map.get('categoryId') || this.categoryId;
    //   let page = Number(map.get('page')) || this.currentPage || 1;

    //   if (this.categoryId !== categoryId || this.page !== this.currentPage) {
    //     if (this.categoryId !== categoryId) {
    //       page = 1;
    //     }

    //     this.categoryId = categoryId;
    //     this.page = page;
    //     this.currentPage = page;

    //     return this.customerDao
    //       .getArticles(this.customer.id, page, categoryId)
    //       .subscribe((articles) => {
    //         this.articles = articles;
    //       });
    //   }
    // });
  }



  scrollTop() {
    const config: ScrollToConfigOptions = {
      target: 'articlesHeader'
    };
    this._scrollToService.scrollTo(config);
  }
}
