import {Restangular} from 'ngx-restangular';
import {environment} from '../../../environments/environment';

export abstract class AbstractDao {
  path: string;
  protected constructor(protected restangular: Restangular) {}

  protected getList() {
    return this.restangular.all(this.path);
  }

  protected getPath(id) {
    return this.restangular
      .one(`${this.path}/${id}`);
  }

  protected getOnePath(id: string): string {
    return `${environment.api}/api${this.path}/${id}`;
  }
}
