import {Component, OnInit} from '@angular/core';
import {ParamMap, ActivatedRoute} from '@angular/router';
import {ICustomer} from '../../shared/model/customer';
import {IArticle} from '../../shared/model/article';
import {CustomerDao} from '../../shared/dao/customer.dao';
import {ArticleDao} from '../../shared/dao/article.dao';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  customer: ICustomer;
  article: IArticle;
  linkedArticles: IArticle[];

  constructor(private route: ActivatedRoute, private customerDao: CustomerDao, private articleDao: ArticleDao) {
  }

  ngOnInit(): void {
    this.customer = this.route.snapshot.parent.data.customer;
    this.article = this.route.snapshot.data.article;

    this.getLinkedArticles();

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      const articleId = paramMap.get('articleId');
      if (articleId) {
        this.articleDao.getArticle(articleId).subscribe((article) => {
          this.article = article;

          window.scrollTo(0, 0);
          this.getLinkedArticles();
        });
      }
    });
  }

  getLinkedArticles() {
    this
      .customerDao
      .getArticles(this.customer.id, 1, String(this.article.category.id))
      .subscribe((articles) => this.linkedArticles = articles.filter((item) => {
        return item.id != this.article.id;
      }).slice(0, 3));
  }
}
