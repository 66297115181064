import {Component, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { IArticle } from '../shared/model/article';
import { ICustomer } from '../shared/model/customer';
import {IBeehive} from '../shared/model/beehive';
import {CustomerService} from '../shared/service/customer.service';
import { IImage } from '../shared/model/image';
import { SpinnerComponent } from '../_components/spinner/spinner.component';
import { FitBoundsOptions, icon, LatLng, latLng, Layer, MapOptions, Marker, marker, tileLayer } from 'leaflet';
import 'leaflet/dist/images/marker-icon-2x.png';
import 'leaflet/dist/images/marker-shadow.png';
import { AddressPipe } from '../utils/pipe/address.pipe';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  leafletOptions: MapOptions;
  customer: ICustomer;
  fitBounds: FitBoundsOptions;
  beehives: IBeehive[];
  beehiveId: number;
  articles: IArticle[];
  customerId: string;
  image: IImage;
  interval: any;
  countBees: number;
  countHonney: number;
  elementInfo: any;
  page: string;
  segments: string[];
  centerMap: LatLng;
  loading: boolean

  constructor(private renderer: Renderer2, private route: ActivatedRoute, private customerService: CustomerService, private addressPipe: AddressPipe) {
    route.url.subscribe(() => {
      this.segments = window.location.pathname.split('/')
      this.segments.pop();
    })
    this.page = window.location.origin;
  }

  ngOnInit(): void {
    this.elementInfo = []
    this.route.pathFromRoot[1].paramMap.subscribe((data) => {

      this.customerId = data.get('customerId');
      const customerId =  this.customerId;

      this.customerService.getCustomer(customerId).subscribe(customer => {
        this.customer = customer;
        this.initMaps();

        this.image = customer.image;
        this.beehives = customer.beehives

        console.log(this.beehives);

        //Count number of bees and honney
        let counter_bee: number[] = []
        let counter_honney: number[] = []
        let beehive_id: number;
        this.beehives.map(beehive => {
          counter_bee.push(beehive.lastCalculatedMeasure.beeCount)
          if (beehive.lastCalculatedMeasure.honeyWeight >= 0) {
            counter_honney.push(beehive.lastCalculatedMeasure.honeyWeight)
          }
          beehive_id = beehive.id
        })
        this.beehiveId = beehive_id
        this.countBees = counter_bee.reduce((a, b) => a + b, 0)
        this.countHonney = Math.round(counter_honney.reduce((a, b) => a + b, 0))

      });
    });
  }

  public initMaps() {

    const layers: Layer[] = [];

    var popupOptions: any = { 'maxWidth': 'auto', 'className': 'another-popup' } // classname for another popup

    if (this.customer && this.customer.beehives.length) {
      for (const beehive of this.customer.beehives) {
        this.centerMap = latLng(beehive.latitude, beehive.longitude);
        const position: LatLng = latLng(beehive.latitude, beehive.longitude);
        const mark: Marker = marker(position, {
          title: beehive.name,
          icon: icon({
            iconUrl: 'assets/images/mascotte_sand.png'
          })
        }).bindPopup(this.renderPopup(beehive), popupOptions);
        this.elementInfo = (mark.bindPopup(this.renderPopup(beehive), popupOptions))
        layers.push(mark);
      }
    }

    // const bounds = featureGroup(layers).getBounds();

    this.leafletOptions = {
      zoom: 12,
      center: this.centerMap,
      layers: [
        tileLayer('https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'}),
        ...layers
      ],
    };
  }


  private renderPopup(beehive: IBeehive) {
    return `
      <div class="popup-image _px-sm _pt-sm _pb-xs _rounded-card " style="background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://api.bekeeper.fr/${beehive.image ? beehive.image.path : ''}'); background-size: cover; background-repeat: no-repeat;">
        <p class="leaflet-popup-title _text-white">${beehive.name}</p>
        <p class="leaflet-popup-address _text-white">${this.addressPipe.transform(beehive.address)}</p>
        <a class="_w-full" href='${this.page + '/' + this.segments[1] + '/' + this.segments[2] + '/' + 'ruches/' + beehive.id}' ><button class="_mt-xs _w-full _bg-white _text-brown _p-xs _rounded-card _font-bold"> Voir le détail </button></a>
      </div>`;
  }
}
