import {Component, Input} from '@angular/core';
import { IBeehive } from '../../../shared/model/beehive';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Period, periodQueryParamName} from '../../../utils/enum/period.enum';
import { IMeasure } from '../../../shared/model/measure';
import { BeehiveDao } from '../../../shared/dao/beehive.dao';
import * as moment from 'moment';

@Component({
  selector: 'app-beehives-list-item',
  templateUrl: './beehivesListItem.component.html'
})
export class BeehivesListItemComponent {
  @Input() beehive: IBeehive;
  @Input() customerId: string;
  honeyWeight: number;
  measures: IMeasure[];
  internalTemperatureData: any;
  Period = Period;
  interval: any;
  humidityChartData:any
  currentPeriod: Period | string;
  loading: boolean

  constructor(private route: ActivatedRoute, private beehiveDao: BeehiveDao,){}

  ngOnInit(): void {
    this.honeyWeight = Math.round(this.beehive.lastCalculatedMeasure.honeyWeight)

    console.log(this.beehive.lastCalculatedMeasure);

    this.currentPeriod = Period.Daily;

    this.route.queryParamMap.subscribe((map: ParamMap) => {
      this.currentPeriod = map.get(periodQueryParamName);
      if (!this.currentPeriod) {
        this.currentPeriod = Period.Daily;
      }

      this.loading = true;
      this.fetchData().subscribe(() => {

        this.loadInterval();

      });
    });


  }


  loadInterval() {
    this.interval = setInterval(() => {
      this.fetchData();
    }, 1800000);
  }

  fetchData() {
    const mesureObsrv = this.beehiveDao.getMeasures(this.beehive.id, this.currentPeriod);
    mesureObsrv.subscribe((measures: IMeasure[]) => {
      this.loadData(measures);
        this.loading = false;

    });

    return mesureObsrv;
  }

  loadData(measures: IMeasure[]) {

    this.measures = measures;

    for (const measure of measures) {
      this.internalTemperatureData = measure.temperature
      // this.internalHumidityData.data.push({x: moment(measure.recorded).toDate(), y: measure.humidity});

    }
    // this.loading = false;

    // this.humidityChartData = this.internalHumidityData;

  }

}
