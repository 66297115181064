import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Period} from '../enum/period.enum';

@Injectable()
export class ChartOptionsBuilder {
  chartOptions: any;

  constructor() {
    this.chartOptions = {};
  }

  setResponsive(responsive: boolean = true) {
    this.chartOptions.responsive = responsive;

    return this;
  }

  formatTimeTooltip(format: string = 'DD MMM YYYY HH:mm:ss') {
    this.chartOptions.tooltips = {
      callbacks: {
        title: (tooltipItems, data) => {
          const date = moment(tooltipItems[0].xLabel);
          return date.format(format);
        }
      }
    };

    return this;
  }

  setScalesBasedOnPeriod(period: string) {
    let unit: string;
    switch (period) {
      case Period.Monthly:
        unit = 'week';
        break;
      case Period.Weekly:
        unit = 'day';
        break;
      case Period.Daily:
      default:
        unit = 'hour';
        break;
    }

    this.chartOptions.scales = {
      xAxes: [{
        type: 'time',
        display: true,
        time: {
          unit: unit,
          displayFormats: {
            hour: 'HH[h]',
            day: 'DD/MM',
            week: 'DD/MM/YYYY'
          }
        },
      }],
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: true,
        }
      }]
    };

    return this;
  }

  build() {
    return this.chartOptions;
  }
}
