import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ICategory} from '../model/category';
import {CategoryDao} from '../dao/category.dao';

@Injectable()
export class CategoriesResolve implements Resolve<ICategory[]> {
  constructor(private categoryDao: CategoryDao) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICategory[]> | Promise<ICategory[]> | ICategory[] {
    return this.categoryDao.getCategories();
  }
}
