import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {ICustomer} from '../model/customer';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractResolve} from './abstract.resolve';
import {catchError} from 'rxjs/operators';
import {CustomerService} from '../service/customer.service';

@Injectable()
export class CustomerResolve extends AbstractResolve implements Resolve<ICustomer> {
  constructor(private customerService: CustomerService, router: Router) {
    super(router);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICustomer> {
    return this
      .customerService
      .getCustomer(route.paramMap.get('customerId'))
      .pipe(catchError(err => this.redirectToNotFoundPage(err, route)));
  }
}
