import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {RestangularModule} from 'ngx-restangular';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {SharedModule} from './shared/shared.module';
import {BeehivesComponent} from './beehives/beehives.component';
import {environment} from '../environments/environment';
import {NotFoundComponent} from './notfound/notfound.component';

import {CollapseModule} from 'ngx-bootstrap/collapse';
import {AbstractComponent} from './abstract/abstract.component';

import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {ArticlesComponent} from './articles/articles.component';
import {ModalModule, PaginationModule} from 'ngx-bootstrap';
import {ArticleComponent} from './articles/article/article.component';
import {FormsModule} from '@angular/forms';
import {BeehiveComponent} from './beehives/beehive/beehive.component';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {ComponentsModule} from './_components/components.module';
import {UtilsModule} from './utils/utils.module';
import {OverviewComponent} from './overview/overview.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {ResponseInterceptor} from './utils/interceptors/restangular.interceptors';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {CustomerHeaderInterceptor} from './utils/interceptors/customer-header.interceptor';
import {CollectionInterceptor} from './utils/interceptors/collection.interceptor';

registerLocaleData(localeFr, 'fr');

export function RestangularConfigFactory(RestangularProvider) {
  RestangularProvider.setBaseUrl(environment.api + '/api');
  RestangularProvider.setDefaultHeaders({'Accept': 'application/ld+json, application/json'});
  RestangularProvider.setRestangularFields({
    id: '@id',
    selfLink: '@id'
  });
  RestangularProvider.setSelfLinkAbsoluteUrl(false);

  RestangularProvider.addResponseInterceptor(ResponseInterceptor);
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    BeehivesComponent,
    NotFoundComponent,
    AbstractComponent,
    ArticlesComponent,
    ArticleComponent,
    OverviewComponent,
    BeehiveComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    ComponentsModule,
    UtilsModule,
    RestangularModule.forRoot([], RestangularConfigFactory),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ScrollToModule.forRoot(),
    LeafletModule.forRoot(),
    PopoverModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomerHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CollectionInterceptor, multi: true }
  ]
})
export class AppModule {
}
