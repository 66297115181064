import {Injectable} from '@angular/core';
import {AbstractDao} from './abstract.dao';
import {Restangular} from 'ngx-restangular';

@Injectable()
export class ArticleDao extends AbstractDao {
  path = '/articles';

  constructor(protected restangular: Restangular) {
    super(restangular);
  }

  getArticle(id) {
    return this.getPath(id).get();
  }
}
