import {NgModule} from '@angular/core';
import {PeriodHelper} from './helpers/period.helper';
import {IconUrlPipe} from './pipe/iconUrl.pipe';
import {StripHtmlPipe} from './pipe/stripHtml.pipe';
import {AddressPipe} from './pipe/address.pipe';
import {ImagePipe} from './pipe/image.pipe';
import {TruncatePipe} from './pipe/truncate.pipe';
import {SafeHtmlPipe} from './pipe/safeHtml.pipe';
import {PhonePipe} from './pipe/phone.pipe';

@NgModule({
  declarations: [
    AddressPipe,
    IconUrlPipe,
    ImagePipe,
    TruncatePipe,
    StripHtmlPipe,
    SafeHtmlPipe,
    PhonePipe
  ],
  providers: [
    PeriodHelper,
    AddressPipe
  ],
  exports: [
    AddressPipe,
    IconUrlPipe,
    ImagePipe,
    TruncatePipe,
    StripHtmlPipe,
    SafeHtmlPipe,
    PhonePipe
  ]
})
export class UtilsModule {
}
