export enum Color {
  BLACK = '#000',
  YELLOW = '#FBB03B'
}

export class ChartColor {
  static getBlack() {
    return {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: [ '#FDD835'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100]
      }
    };
  }

  static getYellow() {
    return {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100]
      },
    };
  }
}
