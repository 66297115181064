import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Period, periodQueryParamName} from '../../utils/enum/period.enum';
import {ICustomer} from '../../shared/model/customer';
import {IBeehive} from '../../shared/model/beehive';
import {MeasureDao} from '../../shared/dao/measure.dao';
import { IMeasure } from '../../shared/model/measure';
import {IImage} from '../../shared/model/image';
import * as moment from 'moment';
import {ChartItem} from '../../shared/dataModel/chartItem';
import {ChartOptionsBuilder} from '../../utils/builder/chartOptions.builder';
import {CalculatedMeasureDao} from '../../shared/dao/calculatedMeasure.dao';
import {WeatherDao} from '../../shared/dao/weather.dao';
import {ICalculatedMeasure} from '../../shared/model/calculatedMeasure';
import {IWeather} from '../../shared/model/weather';
import {BeehiveInfo} from './beehiveInfo';
import {Color} from '../../utils/enum/colors.enum';
import { BeehiveDao } from '../../shared/dao/beehive.dao';
import { AddressPipe } from '../../utils/pipe/address.pipe';

@Component({
  selector: 'app-beehive',
  styleUrls: ['./beehive.component.scss'],
  templateUrl: './beehive.component.html'
})
export class BeehiveComponent extends BeehiveInfo implements OnInit, OnDestroy {
  image: string;
  address: any;
  beeCount: number;
  honeyCount: number;
  customer: ICustomer;
  beehive: IBeehive;
  measures: IMeasure[];
  calculatedMeasures: ICalculatedMeasure[];
  weather: IWeather;
  Period = Period;
  currentPeriod: any;

  temperatureChartData: Array<ChartItem> = [];
  humidityChartData: Array<ChartItem> = [];
  weightChartData: Array<ChartItem> = [];

  lineChartOptions: any = {};
  interval: any;
  currentMeasure: IMeasure;
  previousMeasure: IMeasure;
  loading: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private measureDao: MeasureDao,
    private beehiveDao: BeehiveDao,
              private addressPipe: AddressPipe,
              private weatherDao: WeatherDao, private calculatedMeasureDao: CalculatedMeasureDao) {
    super();
    const externalItem = new ChartItem('Extérieur', Color.YELLOW);
    const internalItem = new ChartItem('Intérieur', Color.BLACK);
    this.temperatureChartData.push(externalItem);
    this.temperatureChartData.push(internalItem);
    this.humidityChartData.push(externalItem);
    this.humidityChartData.push(internalItem);
    this.weightChartData.push(externalItem);

    this.lineChartOptions = (new ChartOptionsBuilder())
      .setResponsive(true)
      .formatTimeTooltip('DD MMM YYYY HH:mm:ss')
      .setScalesBasedOnPeriod(Period.Daily)
      .build();
  }

  ngOnInit(): void {

    this.customer = this.route.parent.snapshot.data.customer;
    this.beehive = this.route.snapshot.data.beehive;


    this.customer.beehives.map((beehive) => {
      if (beehive.id === this.beehive.id) {
        this.image = beehive.image ? beehive.image.path : '';
        this.address = this.addressPipe.transform(beehive.address)
        this.beeCount = beehive.lastCalculatedMeasure.beeCount
        this.honeyCount = beehive.lastCalculatedMeasure.honeyWeight
      }
    });

    this.currentPeriod = Period.Daily;

    this.route.queryParamMap.subscribe((map: ParamMap) => {
      this.loading = true;

      this.currentPeriod = map.get(periodQueryParamName);
      if (!this.currentPeriod || !Object.values(Period).includes(this.currentPeriod)) {
        this.currentPeriod = Period.Daily;
      }

      this.stopInterval();


      this.fetchData().subscribe(() => {
        this.loadInterval();
      });
    });
  }

  ngOnDestroy(): void {
    this.stopInterval();
  }

  loadInterval() {
    console.log(BeehiveComponent.name, 'Starting interval...');
    this.interval = setInterval(() => {
      console.log(`Refreshing datas with ${this.currentPeriod} period...`);


      this.fetchData();
    }, 1800000);
  }

  stopInterval() {
    if (this.interval) {
      console.log(BeehiveComponent.name, 'Stopping interval...');
      clearInterval(this.interval);
    }
  }

  fetchData() {
    console.log(BeehiveComponent.name, 'Fetch data...');
    const mesureObsrv = this.beehiveDao.getMeasures(this.beehive.id, this.currentPeriod);
    mesureObsrv.subscribe((measures: IMeasure[]) => {

      this.loadData(measures);

    });

    this.weatherDao
      .getCurrentWeather(String(this.beehive.id))
      .subscribe(weather => this.weather = weather);

    this.calculatedMeasureDao
      .getMeasures(this.beehive.id)
      .subscribe(measures => this.calculatedMeasures = measures);


    return mesureObsrv;
  }

  loadData(measures: IMeasure[]) {
    this.measures = measures;
    this.currentMeasure = this.measures[this.measures.length - 1];
    this.previousMeasure = this.measures[this.measures.length - 2];

    const outsideTemperatureData = new ChartItem('Exterieur', Color.YELLOW);
    const internalTemperatureData = new ChartItem('Intérieur', Color.BLACK);
    const outsideHumidityData = new ChartItem('Exterieur', Color.YELLOW);
    const internalHumidityData = new ChartItem('Intérieur', Color.BLACK);
    const weightData = new ChartItem('Poids', Color.YELLOW);


    outsideTemperatureData.pointRadius = [];
    internalTemperatureData.pointRadius = [];
    for (const measure of measures) {
      outsideTemperatureData.data.push({x: moment(measure.recorded).toDate(), y: measure.outsideTemperature});
      internalTemperatureData.data.push({x: moment(measure.recorded).toDate(), y: measure.temperature});
      outsideTemperatureData.pointRadius.push(0);
      internalTemperatureData.pointRadius.push(0);

      outsideHumidityData.data.push({x: moment(measure.recorded).toDate(), y: measure.outsideHumidity});
      internalHumidityData.data.push({x: moment(measure.recorded).toDate(), y: measure.humidity});
      outsideHumidityData.pointRadius.push(0);
      internalHumidityData.pointRadius.push(0);

      weightData.data.push({x: moment(measure.recorded).toDate(), y: measure.weight});
      weightData.pointRadius.push(0);
    }

    this.temperatureChartData = [outsideTemperatureData, internalTemperatureData];
    this.humidityChartData = [outsideHumidityData, internalHumidityData];
    this.weightChartData = [weightData];
    this.loading = false;

  }
}
