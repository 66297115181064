import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {IBeehive} from '../model/beehive';
import {Observable} from 'rxjs';
import {BeehiveDao} from '../dao/beehive.dao';
import {AbstractResolve} from './abstract.resolve';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class BeehiveResolve extends AbstractResolve implements Resolve<IBeehive> {

  constructor(private beehiveDao: BeehiveDao, router: Router) {
    super(router);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IBeehive> | IBeehive {
    const customerId = route.parent.params.customerId;
    const beehiveId = route.params.beehiveId;

    return this.beehiveDao
      .getBeehive(beehiveId)
      .pipe(catchError(err => this.redirectToNotFoundPage(err, route)))
      .pipe(map((beehive: IBeehive) => beehive));
  }
}
