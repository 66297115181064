import {NgModule} from '@angular/core';
import {LineChartComponent} from './lineChart/lineChart.component';
import {PaneMetricsComponent} from './paneMetrics/paneMetrics.component';
import {PaneResultsComponent} from './paneResults/paneResults.component';
import {UtilsModule} from '../utils/utils.module';
import {RouterModule} from '@angular/router';
import {HoneyPotComponent} from './honeyPot/honeyPot.component';
import {CommonModule} from '@angular/common';
import {BeehivesListComponent} from './beehivesList/beehivesList.component';
import {ArticlesListComponent} from './articlesList/articlesList.component';
import {PaginationModule, PopoverModule} from 'ngx-bootstrap';
import {BeehivesListItemComponent} from './beehivesList/beehivesListItem/beehivesListItem.component';
import {WeatherComponent} from './weather/weather.component';
import {HelpInfoComponent} from './helpInfo/helpInfo.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import { BannerComponent } from './banner/banner.component';
import { ButtonComponent } from './button/button.component';
import { SwiperModule } from 'swiper/angular';
import { SpinnerComponent } from './spinner/spinner.component';


@NgModule({
  imports: [CommonModule, UtilsModule, RouterModule, SwiperModule, PaginationModule.forRoot(),
    PopoverModule.forRoot(), NgApexchartsModule],
  declarations: [LineChartComponent,SpinnerComponent, PaneMetricsComponent, PaneResultsComponent, WeatherComponent, HelpInfoComponent,
    HoneyPotComponent, BeehivesListComponent, ArticlesListComponent, BeehivesListItemComponent, BannerComponent, ButtonComponent, SpinnerComponent],
  exports: [SpinnerComponent, LineChartComponent, PaneMetricsComponent, PaneResultsComponent, WeatherComponent, HelpInfoComponent,
    HoneyPotComponent, BeehivesListComponent, ArticlesListComponent, BannerComponent, ButtonComponent, BeehivesListItemComponent]
})
export class ComponentsModule {
}
