import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { ICustomer } from '../../shared/model/customer';

import {CustomerService} from '../../shared/service/customer.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() image: string;
  customer: ICustomer;
  customerId: string;


  constructor(private route: ActivatedRoute, private customerService: CustomerService) { }

  ngOnInit(): void {

    this.route.pathFromRoot[1].paramMap.subscribe((data) => {
      this.customerId = data.get('customerId');
      const customerId =  this.customerId;

      this.customerService.getCustomer(customerId).subscribe(customer => {
        this.customer = customer;
      });
    });
  }

}
