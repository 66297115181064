import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {AbstractDao} from './abstract.dao';

@Injectable()
export class CategoryDao extends AbstractDao {
  path = '/categories';

  constructor(protected restangular: Restangular) {
    super(restangular);
  }

  getCategories() {
    return this.getList().getList();
  }
}
