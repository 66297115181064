import {Component, Input} from '@angular/core';
import { IArticle } from '../../shared/model/article';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-articles-list',
  templateUrl: './articlesList.component.html'
})
export class ArticlesListComponent {
  @Input() articles: IArticle[];
  @Input() customerId: string;
  @Input() isOverview: number;
}
