import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

@Injectable()
export class CollectionInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        map(resp => {
          if (resp instanceof HttpResponse) {
            if (resp.body && resp.body['@type'] && resp.body['@type'] === 'hydra:Collection') {
              return resp.clone({body: resp.body['hydra:member']});
            }
          }

          return resp;
        })
      );
  }
}
