import {Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() colorBg: string;
  @Input() colorText: string;
  @Input() fullWidth: boolean;
  widthElement: string;

  constructor() { }

  ngOnInit(): void {
    this.widthElement = this.fullWidth ? '_w-full' : '';
  }

}
