import {Pipe, PipeTransform} from '@angular/core';
import {IAddress} from '../../shared/model/address';

@Pipe({name: 'address'})
export class AddressPipe implements PipeTransform {
  transform(address: IAddress): string {
    return `${address.address}
    ${address.zipCode} ${address.city}`;
  }
}
