import {Injectable} from '@angular/core';
import {Period} from '../enum/period.enum';
import * as moment from 'moment';

@Injectable()
export class PeriodHelper {

  private getRangeDay() {
    return [
      {
        method: 'after',
        value: moment().subtract(1, 'days')
      },
      {
        method: 'before',
        value: moment()
      }
    ];
  }

  private getRangeWeek() {
    return [
      {
        method: 'after',
        value: moment().subtract(1, 'week')
      },
      {
        method: 'before',
        value: moment()
      }
    ];
  }

  private getRangeMonth() {
    return [
      {
        method: 'after',
        value: moment().subtract(1, 'month')
      },
      {
        method: 'before',
        value: moment()
      }
    ];
  }

  private periodToRangeDate(period: string) {
    switch (period) {
      case Period.Daily:
        return this.getRangeDay();
      case Period.Weekly:
        return this.getRangeWeek();
      case Period.Monthly:
        return this.getRangeMonth();
    }
  }

  periodToQueryParams(key: string, period: string) {
    const queryParams = {};
    const range = this.periodToRangeDate(period);

    for (const rangeValue of range) {
      queryParams[`${key}[${rangeValue.method}]`] = rangeValue.value.format().split('+')[0];
    }

    return queryParams;
  }
}
