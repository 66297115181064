import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Injectable} from '@angular/core';
import {CustomerService} from '../../shared/service/customer.service';
import {flatMap} from 'rxjs/operators';
import {ICustomer} from '../../shared/model/customer';

@Injectable()
export class CustomerHeaderInterceptor implements HttpInterceptor {
  constructor(private customerService: CustomerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.customerService
      .getCustomer()
      .pipe(
        flatMap((customer: ICustomer) => {
            const authReq = req.clone({
              headers: req.headers.set('X-CUSTOMER-ID', customer.id)
            });

            return next.handle(authReq);
          }
        )
      );
  }
}
