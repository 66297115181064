import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {ICustomer} from '../model/customer';
import {Observable} from 'rxjs';
import {IArticle} from '../model/article';
import {AbstractDao} from './abstract.dao';

@Injectable()
export class CustomerDao extends AbstractDao {
  path = '/customers';

  constructor(protected restangular: Restangular) {
    super(restangular);
  }

  get(id: string): Observable<ICustomer> {
    return this.getPath(id).get();
  }

  getArticles(id: string, page: number = 1, categoryId: string = 'all'): Observable<IArticle[]> {
    return this.getPath(id).all('articles').getList({page: page, categoryId: categoryId});
  }
}
