import {Injectable} from '@angular/core';
import {CustomerDao} from '../dao/customer.dao';
import {ICustomer} from '../model/customer';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {IArticle} from '../model/article';
import {map} from 'rxjs/operators';

@Injectable()
export class CustomerService {
  private customer: ICustomer;

  constructor(private customerDao: CustomerDao) {
  }

  getCustomer(id?: string): Observable<ICustomer> {
    if (this.customer) {
      return of(this.customer);
    }

    return id ? this.customerDao.get(id).pipe(map((customer: ICustomer) => {
      this.customer = customer;
      return customer;
    })) : null;
  }

  getArticles(id: string): Observable<IArticle[]> {
    return this.customerDao.getArticles(id);
  }
}
