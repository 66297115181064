import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ICustomer} from '../shared/model/customer';
import {ActivatedRoute} from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap';
import { CustomerService } from '../shared/service/customer.service';
import {IImage} from '../shared/model/image';
@Component({
  selector: 'app-abstract',
  templateUrl: './abstract.component.html',
  styleUrls: ['./abstract.component.scss']
})
export class AbstractComponent implements OnInit {
  customer: ICustomer;
  isMenuCollapsed: boolean;
  image: IImage;
  page: string;
  homePopover = 'Bienvenue sur l’application Be Keeper ! Vous trouverez ici le tableau de bord vous permettant de visualiser en un seul coup d’œil l’ensemble de vos ruches et les actualités associées.';
  actuPopover = 'Toutes les actualités de vos ruches, de la vie des abeilles ou de Be Keeper.';
  beehivePopover = 'Le détail de chaque ruche connectée en temps réel (indicateurs, position, météo, etc.).';

  @ViewChild('homePop') homePop: PopoverDirective;
  @ViewChild('actuPop') actuPop: PopoverDirective;
  @ViewChild('beehivePop') beehivePop: PopoverDirective;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 575) {
      this.homePop.hide();
      this.actuPop.hide();
      this.beehivePop.hide();
    }
  }

  popMouseEnter(pop: PopoverDirective) {
    if (window.innerWidth > 575) {
      pop.show();
    }
  }

  popMouseLeave(pop: PopoverDirective) {
    pop.hide();
  }


  constructor(private route: ActivatedRoute, private customerService: CustomerService) {
    route.url.subscribe(() => {
      const segments = window.location.pathname.split('/')
      this.page = segments.pop() || segments.pop(); // Handle potential trailing slash
      return this.page;
    });
  }

  ngOnInit(): void {

    this.customer = this.route.snapshot.data.customer;
    const customerId = this.customer.id;

    this.customerService.getCustomer(customerId).subscribe(customer => {
      this.image = customer.image;
    });
    this.isMenuCollapsed = true;
  }
}
