import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ICustomer} from '../shared/model/customer';
import {ActivatedRoute} from '@angular/router';
import {CustomerDao} from '../shared/dao/customer.dao';
import {IWeather} from '../shared/model/weather';
import * as moment from 'moment';
import {WeatherDao} from '../shared/dao/weather.dao';
import {CustomerService} from '../shared/service/customer.service';
import {IBeehive} from '../shared/model/beehive';
import {featureGroup, FitBoundsOptions, icon, LatLng, latLng, Layer, MapOptions, Marker, marker, tileLayer} from 'leaflet';

import 'leaflet/dist/images/marker-icon-2x.png';
import 'leaflet/dist/images/marker-shadow.png';
import {AddressPipe} from '../utils/pipe/address.pipe';
import {IImage} from '../shared/model/image';

@Component({
  selector: 'app-beehives',
  templateUrl: './beehives.component.html',
  styleUrls: ['./beehives.component.scss']
})
export class BeehivesComponent implements OnInit, OnDestroy {

  leafletOptions: MapOptions;
  fitBounds: FitBoundsOptions;
  customer: ICustomer;
  beehives: IBeehive[];
  weather: IWeather;
  today: number;
  now: moment.Moment;
  interval: any;
  image: IImage;
  ruchesInfo = `Les informations (quantité de miel, nombre d’abeilles et taux d’activité de la ruche)
  calculées par l’algorithme Be Keeper à partir des données mesurées par chaque capteurs.`;

  constructor(private route: ActivatedRoute, private customerDao: CustomerDao, private addressPipe: AddressPipe,
              private weatherDao: WeatherDao, private customerService: CustomerService) {
    this.today = Date.now();
    this.now = moment();
  }

  ngOnInit() {
    this.customer = this.route.parent.snapshot.data.customer;
    const customerId = this.customer.id;

    this.customerService.getCustomer(customerId).subscribe(customer => {
      this.image = customer.image;
      this.beehives = customer.beehives;
    });

    console.log(BeehivesComponent.name, 'Starting interval...');
    this.interval = window.setInterval(() => {
      console.log(BeehivesComponent.name, 'Reload page for refreshing datas...');
      window.location.reload();
    }, 1800000);
  }

  ngOnDestroy() {
    if (this.interval) {
      console.log(BeehivesComponent.name, 'Stopping interval...');
      window.clearInterval(this.interval);
    }
  }
}
