import {Component, HostListener, Input, ViewChild} from '@angular/core';
import {PopoverDirective} from 'ngx-bootstrap';

@Component({
  selector: 'app-help-info',
  templateUrl: './helpInfo.component.html',
})
export class HelpInfoComponent {
  @Input() message: string;
  @ViewChild('pop') pop: PopoverDirective;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 575) {
      this.pop.hide();
    }
  }
}
