import {NgModule} from '@angular/core';
import {CustomerDao} from './dao/customer.dao';
import {CustomerResolve} from './resolve/customer.resolve';
import {ArticlesResolve} from './resolve/articles.resolve';
import {WeatherDao} from './dao/weather.dao';
import {CategoryDao} from './dao/category.dao';
import {CategoriesResolve} from './resolve/categories.resolve';
import {CategoryResolve} from './resolve/category.resolve';
import {ArticleDao} from './dao/article.dao';
import {ArticleResolve} from './resolve/article.resolve';
import {BeehiveDao} from './dao/beehive.dao';
import {BeehiveResolve} from './resolve/beehive.resolve';
import {MeasureDao} from './dao/measure.dao';
import {UtilsModule} from '../utils/utils.module';
import {BeehiveRedirectResolve} from './resolve/beehiveRedirect.resolve';
import {CalculatedMeasureDao} from './dao/calculatedMeasure.dao';
import {CustomerService} from './service/customer.service';

@NgModule({
  declarations: [
  ],
  imports: [
    UtilsModule
  ],
  providers: [
    CustomerDao,
    WeatherDao,
    CategoryDao,
    ArticleDao,
    BeehiveDao,
    MeasureDao,
    CalculatedMeasureDao,
    CustomerResolve,
    ArticlesResolve,
    ArticleResolve,
    CategoriesResolve,
    CategoryResolve,
    BeehiveResolve,
    BeehiveRedirectResolve,
    CustomerService
  ],
  exports: [
  ]
})
export class SharedModule {
}
