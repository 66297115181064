import {AbstractDao} from './abstract.dao';
import {Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {ICalculatedMeasure} from '../model/calculatedMeasure';
import {Observable} from 'rxjs';

@Injectable()
export class CalculatedMeasureDao extends AbstractDao {
  path = '/calculated_measures_last2';

  constructor(protected restangular: Restangular) {
    super(restangular);
  }

  getMeasures(beehiveId: number): Observable<ICalculatedMeasure[]> {
    return this.getList().getList({beehiveId: beehiveId});
  }
}
