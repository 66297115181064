import {Component, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {IMeasure} from '../../shared/model/measure';
import {ArticleDao} from '../../shared/dao/article.dao';
import {CustomerDao} from '../../shared/dao/customer.dao';
import {IArticle} from '../../shared/model/article';
import {ICustomer} from '../../shared/model/customer';

@Component({
  selector: 'app-pane-metrics',
  templateUrl: './paneMetrics.component.html',
  styleUrls: ['./paneMetrics.component.scss']
})
export class PaneMetricsComponent implements OnInit, OnChanges, OnDestroy {
  static menuClassOpen = 'pane-right-open';
  isMenuOpened = false;
  @Input() measures: IMeasure[];
  currentMeasure: IMeasure;
  previousMeasure: IMeasure;
  articles: IArticle[];
  customer: ICustomer;

  constructor(private renderer: Renderer2, private route: ActivatedRoute, private customerDao: CustomerDao) {
  }

  getMeasures() {
    if (this.measures && this.measures.length) {
      this.currentMeasure = this.measures[0];
      this.previousMeasure = this.measures[1];
    } else {
      this.currentMeasure = null;
      this.previousMeasure = null;
    }
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'page-beehive');

    this.getMeasures();

    this.customer = this.route.parent.snapshot.data.customer;
    this.customerDao.getArticles(this.customer.id).subscribe(articles => this.articles = articles);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getMeasures();
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'page-beehive');
  }

  toggleMenu() {
    this.isMenuOpened = !this.isMenuOpened;

    if (this.isMenuOpened) {
      this.renderer.addClass(document.body, PaneMetricsComponent.menuClassOpen);
    } else {
      this.renderer.removeClass(document.body, PaneMetricsComponent.menuClassOpen);
    }
  }
}
