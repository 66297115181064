import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {IArticle} from '../model/article';
import {Observable} from 'rxjs';
import {ArticleDao} from '../dao/article.dao';
import {AbstractResolve} from './abstract.resolve';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class ArticleResolve extends AbstractResolve implements Resolve<IArticle> {
  constructor(private articleDao: ArticleDao, router: Router) {
    super(router);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IArticle> | Promise<IArticle> | IArticle {
    const customerId = route.parent.params.customerId;
    const articleId = route.paramMap.get('articleId');

    return this.articleDao.getArticle(articleId)
      .pipe(catchError(err => this.redirectToNotFoundPage(err, route)))
      .pipe(map((article: IArticle) => {
        const customer = article.customers.filter(item => {
          const remoteCustomerId = item.split('/').pop();
          return customerId === remoteCustomerId;
        });

        if (!article.global && !customer.length) {
          this.redirectToNotFoundPage(null, route);
        }

        return article;
      }));
  }

}
