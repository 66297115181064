import {Router} from '@angular/router';

function ResponseInterceptor(data, operation) {
  function populateHref(data) {
    if (data['@id']) {
      data.href = data['@id'].substring(1);
    }
  }

  populateHref(data);

  if ('getList' === operation) {
    const collectionResponse = data['hydra:member'];
    collectionResponse.metadata = {};

    for (const i in data) {
      if ('hydra:member' !== i) {
        collectionResponse.metadata[i] = data[i];
      }
    }

    collectionResponse.forEach((element) => populateHref(element));

    return collectionResponse;
  }

  return data;
}

export {ResponseInterceptor};
