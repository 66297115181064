import {Component, Input} from '@angular/core';
import {IWeather} from '../../shared/model/weather';
import {ICustomer} from '../../shared/model/customer';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html'
})
export class WeatherComponent {
  @Input() weather: IWeather;
  @Input() customer: ICustomer;
}
