import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ICategory} from '../model/category';
import {CategoryDao} from '../dao/category.dao';

@Injectable()
export class CategoryResolve implements Resolve<ICategory> {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ICategory {
    const categories = route.parent.data.categories;
    const categoryId = route.queryParamMap.get('categoryId') || 'all';
    const category = categories.find((elm) => elm.id === categoryId);
    if (categoryId !== 'all' && !category) {
      const customer = route.parent.parent.data.customer;
      this.router.navigate(['/clients', customer.id, 'actualites']);
      return null;
    }

    return category;
  }
}
