import {ChartColor, Color} from '../../utils/enum/colors.enum';
import {ChartTypes} from '../../utils/enum/chartTypes.enum';

export class ChartItem {
  label: string;
  name: string;
  data: Array<any>;
  pointRadius: Array<number>;
  color: {};

  constructor(label: string, color: Color, data?: Array<any>) {
    this.label = label;
    this.name = label;
    this.data = data || [];
    this.pointRadius = [];
    this.color = color;
  }

  private getColor(color: Color): {} {
    switch (color) {
      case Color.BLACK:
        return ChartColor.getBlack();
      case Color.YELLOW:
        return ChartColor.getYellow;
    }
  }
}
