import {Component, Input, OnInit} from '@angular/core';
import {IImage} from '../../shared/model/image';
import {IBeehive} from '../../shared/model/beehive';

@Component({
  'selector': 'app-beehives-list',
  'templateUrl': './beehivesList.component.html'
})
export class BeehivesListComponent implements OnInit {
  @Input() image: IImage;
  @Input() beehives: IBeehive[];
  @Input() customerId: string;
  @Input() isOverview: boolean;
  count: number[];
  beehivesDisplay: IBeehive[];
  otherBeehives: IBeehive[];
  showOtherBeehives: boolean;
  ruchesInfo = `Les informations (quantité de miel, nombre d’abeilles et taux d’activité de la ruche)
  calculées par l’algorithme Be Keeper à partir des données mesurées par chaque capteurs.`;

  ngOnInit(): void {
  }
}

