import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-honey-pot',
  templateUrl: './honeyPot.component.html'
})
export class HoneyPotComponent implements OnInit {
  @Input() quantity: number;
  percent: number;
  imagePath: string;

  ngOnInit(): void {
    this.percent = this.quantity && this.quantity > 0 ? this.quantity * 2 > 100 ? 100 : Math.ceil(this.quantity * 2) : 0;
    this.fetchImagePath();
  }

  private fetchImagePath() {
    let percent = 0;
    if (this.quantity > 12.5) {
      percent = 25;
    }
    if (this.quantity > 25) {
      percent = 50;
    }
    if (this.quantity > 37.5) {
      percent = 75;
    }
    if (this.quantity > 50) {
      percent = 100;
    }

    this.imagePath = `/assets/images/honey-pots/honeypot-${percent}.svg`;
  }
}
