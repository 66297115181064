import {Injectable} from '@angular/core';
import {AbstractDao} from './abstract.dao';
import {Restangular} from 'ngx-restangular';
import {Observable} from 'rxjs';
import {IMeasure} from '../model/measure';
import {PeriodHelper} from '../../utils/helpers/period.helper';

@Injectable()
export class MeasureDao extends AbstractDao {
  path = '/measures';

  constructor(protected restangular: Restangular, private periodHelper: PeriodHelper) {
    super(restangular);
  }

  getMeasures(beehiveId: number, period: string): Observable<IMeasure[]> {
    const queryParams = this.periodHelper.periodToQueryParams('recorded', period);

    return this.getList().getList(Object.assign({beeHive: beehiveId, pagination: false}, queryParams));
  }
}
